.reactflow-container {
  position: relative;
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
  /* min-height: 100vh; */
  max-height: 600px;
}

.reactflow-container .reactflow-wrapper {
  flex-grow: 1;
  height: 100vh;
}

.toolbox {
  position: absolute;
  color: #000000;
  top: 5px;
  left: 5px;
  z-index: 1000;
  padding: 8px;
  border: 2px solid var(--falcon-card-border-color);
  border-radius: 8px;
  width: 150px;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: var(--falcon-btn-falcon-background);
  font-size: 12px;
}

.reactflow-container .toolboxNode {
  color: var(--falcon-headings-color);
  font-weight: 700;
  height: 20px;
  padding: 4px;
  border: 1px solid var(--falcon-card-border-color);
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.reactflow-container .toolboxNode:last-child {
  margin-bottom: 0;
}

.reactflow-container .toolboxNode.mod {
  background: rgba(var(--falcon-info-rgb));
}

.reactflow-container .toolboxNode.action {
  background: rgba(var(--falcon-warning-rgb));
}

.reactflow-container .toolboxNode.utility {
  background: rgba(var(--falcon-success-rgb));
}
