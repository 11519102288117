.node {
  border-radius: 4px;
  background-color: var(--falcon-btn-falcon-background);
  box-shadow: var(--falcon-btn-falcon-hover-box-shadow);
  padding: 12px;
  width: 160px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  font-size: 12px;
  color: var(--falcon-headings-color);
}

.node:hover {
  box-shadow: var(--falcon-btn-falcon-hover-box-shadow);
  color: var(--falcon-btn-falcon-default-hover-color);
}

.content {
  pointer-events: none;
}

.nodeRoot {
  border: 1px solid var(--falcon-card-border-color);
}

.nodeRoot .handleRoot {
  background: #000000;
  z-index: 100;
}

.nodeMod {
  border: 1px solid rgba(var(--falcon-info-rgb));
}

.nodeMod .handleMod {
  background: rgba(var(--falcon-info-rgb));
}

.nodeAction {
  border: 1px solid rgba(var(--falcon-warning-rgb));
}

.nodeAction .handleAction {
  background: rgba(var(--falcon-warning-rgb));
}

.nodeUtility {
  border: 1px solid rgba(var(--falcon-success-rgb));
}

.nodeUtility .handleUtility {
  background: rgba(var(--falcon-success-rgb));
}

.nodeWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 10px;
}

.nodeTitleBar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nodeTitle {
  font-size: 12px;
  font-weight: bold;
  margin-left: 4px;
}

.nodeContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.timeUnitType {
  margin-left: 4px;
}

.timeInput {
  width: 100%;
  margin-top: 4px;
}

.timeUnit {
  width: 100%;
  margin-top: 4px;
}

.destinationSelect {
  margin-left: 4px;
}
